.mainContainer {
   /* border: 2px solid blue; */
   box-sizing: border-box;
   /* height: 800PX; */
   width: 100%;

   display: flex;
   flex-direction: column;
   color: var(--color-gray1);
   background-image: url(../../img/ServiciosImg/telecomunicaciones-bg.png);
   background-repeat: no-repeat;
   /* max-height: 300px; */
}

.headerContainer {
   position: relative;


   display: flex;
   justify-content: center;
   align-items: center;
   /* overflow: hidden; */
   animation-duration: 2000ms;
   animation-name: desvanecido-imagen;
   /* min-height: 500px; */
}

.backOpacity {

   position: absolute;
   height: 100%;
   background-color: rgba(255, 255, 255, 0.3);
   width: 100%;


}

.headerContainer>img {
   width: 100%;

   /* min-height: auto; */
   min-height: 270px;
   max-height: 300px;
   object-fit: cover;


}

@keyframes desvanecido-imagen {
   0% {
      opacity: 0;
   }

   100% {
      opacity: 1;
   }

}

.headerTextContainer {
   position: absolute;
   height: 70%;
   /* width: 80%; */
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   /* border: 1px solid red; */

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: center;

}





/*  ESTILOS ITEMS BLOCKS */

.subItemBlockContainer {
   box-sizing: border-box;
   width: 100%;
   min-height: 350px;
   max-height: 350px;
   /* border: 1px solid red; */

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: row;

   padding: 3rem 1rem;

}

.subItemImageContainer {
   /* border: 1px solid yellow; */
   width: 480px;
   height: 90%;

   margin: 0 5px;

   display: flex;
   justify-content: center;
   align-items: center;

   overflow: hidden;
   border-radius: 5px;
   /* border: 1px solid blue; */
}

.subItemImageContainer>img {
   /* width: 100%; */
   max-height: 220px;
   object-fit: cover;

   animation-duration: 2000ms;
   animation-name: desvanecido-imagen;
   /* box-shadow: 0px 8px 20px 4px rgba(0, 0, 0, 0.1); */
}

.subItemTextContainer {
   margin: 0 5px;
   /* border: 1px solid yellow; */
   width: 480px;
   height: 90%;

   display: flex;
   justify-content: space-evenly;
   align-items: center;
   flex-direction: column;
   color: var(--color-gray1);
}

.subItemTextContainer>button {
   /* border: 3px solid var(--vitech-blue-darker); */
   border: 0;
   border-radius: 20px;
   height: 36px;
   padding: 0 30px;
   background-color: var(--color-gray1);
   font-size: 17px;
   font-weight: bold;
   color: white;
}





/********** PANTALLA CELULAR ********/
@media (max-width:700px) {

   /* ESTILOS HEADER */
   .headerTextContainer {
      font-size: smaller;
      width: 90%;

   }

   .headerContainer {
      overflow: hidden;
      max-height: fit-content;
      height: 280px;
   }

   /* ESTILOS SUB ITEM BLOCK */

   .subItemBlockContainer {
      position: relative;
      flex-direction: column;
      height: 320px;
      overflow: hidden;
      /* padding: 2rem 0; */
   }

   .subItemImageContainer {
      margin: 0;
      width: 70%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      height: 90%;
      opacity: 0.4;
      padding: 1.5rem 0;
   }

   .subItemImageContainer>img {
      height: 260px;
      opacity: 0.5;
   }

   .subItemTextContainer {
      width: 80%;
      z-index: 1;
      font-size: small;
   }


}