/* 
PROPIEDAD NECESARIA PARA ALINEAR A LA DERECHA
LA LISTA DE LINKS DE NAVEGACIÓN DE LA NAV-BAR
*/
@media (min-width: 992px){
    .navBarCustomLinksContainer{
        flex-grow: 0;
        margin-right: 3rem;
    }
    .navBarCustomUl{
        display: flex;
        align-items: center;
    }

}

.navItemCustom{
    position: relative;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px 0.8rem;
    

}

.navBarCustomStyle{
    /* position: sticky; */
    width: 100%;
    top: 0;
    z-index: 5;

    background: rgba( 255, 255, 255, 0.85 );
    box-shadow: 0 2px 10px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 3px );
}