.mainImageContainer {
   /* border: 2px solid blue; */

   position: relative;


}

.mainImageContainer>img {
   width: 100%;

   min-height: 300px;
   max-height: 480px;
   object-fit: cover;
   animation-duration: 1800ms;
   animation-name: desvanecido-imagen;
}

.boxTransp {
   position: absolute;
   background-color: rgba(0, 0, 0, 0.568);
   width: 100%;
   height: 100%;

   z-index: 1;
}

.titlesContainer {
   z-index: 2;

   position: absolute;
   width: 90%;
   height: 120px;
   top: 50%;
   left: 50%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;

   /* border: 1px solid white; */
   transform: translate(-50%, -50%);
   font-weight: lighter;
   color: white;

   animation-duration: 1800ms;
   animation-name: desvanecido-imagen;
}

.titlesContainer>.subtitle {
   font-weight: 200;
}

@keyframes desvanecido-imagen {
   0% {
      width: 110%;
      opacity: 0.3;
   }

   100% {

      opacity: 1;
   }

}