
.solucionesContainer{
    padding: 2vw;
    background-color: var(--bg-soluciones);
    color: white;

}

.solucionesContainer > p {

}

/* font-size: calc(1.325rem + .9vw)!important; */

/************ TILE ESTILOS ******************/
.solucionTile{
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 370px;
    margin: 20px;
    
    /* border: 1px solid red; */
}

.solucionImg{
    width: 100%;
    border-radius: 3px;
}
.textTile{
    position: absolute;
    bottom: 0px;
    margin: 0 5px;

}

.textTile > p {
    font-weight: 100;

}
/*********** FIN TILE ESTILOS ******************/

.tilesContainer{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.buttonVerServicios{
    font-weight: 600;
    font-size: 1.3rem;
    border-radius: 20px;
    color: white;
    background-color: var(--vitech-blue);
    border: 0;
    padding: 5px 20px;
}
.buttonVerServicios:hover{
    background-color: var(--vitech-blue-light);
}