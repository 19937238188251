.title{
    /* color: yellow; */
    margin: 0;
    /* margin-bottom: 3rem; */
    color: var(--color-gray1);
    font-size: calc(1.325rem + .9vw)!important;
}
.relative{
    position: relative;
}

.nuestrosClientesContainer{
    padding: 7vh 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    /* height:  */
    height: 35vh;
    min-height: 150px;
    max-height: 450px;

    /* background: url("../../img/NuestrosClientes/nuestrosClientes.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    
    
    box-sizing: border-box;
    width: 100%;

    position: relative;

    /* border: 3px solid blue; */
}

.containerSlider{
    height: 100%;
    width: 100%; 
    max-width: 70%;
    /* border: 3px solid red; */
    margin: auto;
    overflow: hidden;
    position: relative;
    /* border: 4px solid yellow */
}
.slider{
    /* EL WIDTH Y MARGIN-LEFT LO APLICAMOS EN EL COMPONENTE */
    display: flex;
    width: 100%;
    height: 100%;
    transition: 0.5s;
    transition-timing-function: ease;
    transition-property: margin;
    /* border: 3px solid blue; */
}
.slider_section{
    /* border: 2px solid red ; */
    width: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}
.slider_img{
    /* position: absolute;
    top: 50%; */
    /* transform: translateY(-50%); */
    width: 90%;
    border-radius: 4px;
    /* box-shadow: 0px 8px 9px 0px rgba(0, 0, 0, 0.3); */
    /* object-fit: cover; */
}

@media (max-width: 700px){
    .slider_img{
        width: 97%;
    }
}

.slider_btn{
    position: absolute;
    width: 30px;
    height: 30px;
    /* background: rgb(49, 49, 49); */
    color: black;
    top: 50%;
    transform: translateY(-50%);
    font-size: 25px;
    font-family: monospace;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    opacity: 0.5;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    transition: all;
    transition-duration: 100ms;

    z-index: 10;
}
/* .slider_btn:hover{
    width: 45px;
    height: 45px;
    font-size: 20px;
} */
/* .slider_btn:active{
    font-size: 38px;
    opacity: 1;
} */

.slider_btn_right{
    composes: slider_btn;
    right: 30px;
    transform: translate(50%, -50%);
    
}
.slider_btn_left{
    composes: slider_btn;
    left: 30px;
    transform: translate(-50%, -50%);
}
