.brandsContainer{
    padding: 30px 2vw;
    width: 100%;
    /* background-color: var(--bg-brands); */
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;

    /* border: 5px solid rgb(114, 112, 226); */
}
.brandLogosContainer{
    min-width: 200px;
    width: 100%;
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    /* border: 1px solid rgb(128, 128, 128); */
}

.brandLogoContainer{    
    width: 110px;
    margin: 10px;
    /* border: 1px solid blue; */
}

.logo{
    width: 100%;
    border-radius: 3px;
}

.title{
    width: 100%;
    margin: 20px; 
    /* font-weight: 600; */
    color: var(--color-gray1);
    /* font-family: 'Inter', sans-serif; */
}