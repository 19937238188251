.mainContainer {
   /* border: 2px solid blue; */
   /* background-color: var(--vitech-blue-darkest); */
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   flex-wrap: wrap;
   /* border: 2px solid yellow; */


}

.navContainer {
   display: flex;
   flex-direction: row;
   width: 100%;

   position: sticky;
   top: 0px;
   z-index: 3;
   /* background-color: var(--vitech-blue-darkest); */
   background-color: rgb(24, 24, 24);

}

.serviceContainer {
   box-sizing: border-box;
   /* border: 2px solid yellow; */
   min-width: 30px;
   /* width: 20%; */
   height: 70px;

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   color: white;
   font-size: small;

   transition-property: all;
   transition-duration: 200ms;
}

.serviceContainer:hover {
   cursor: pointer;
   background-color: var(--vitech-blue-darker);
}

@media (max-width: 700px) {
   /* .serviceContainer{
      width: 20%;
   } */

   .serviceContainer>span {
      display: none;
   }

}