body {
  margin: 0;   
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}s



/* NAV BAR */
.justify-space-around{
  display: flex;
  flex-direction: row;
  color: red;
  justify-content: space-around;
}

:root{
  /* COLORES DE VITECH */
  --vitech-blue: #005DAA;
  --vitech-blue-light: #2e7dbd;
  --vitech-blue-dark: #1f5785;
  --vitech-blue-darker: #163e5f;
  --vitech-blue-darkest: #140f30;

  /* WHITES */
--color-white1: #f1f1f9;
--color-white2: #f5f5f7;
--color-white3: #f8f8f8;


  /* GRAYS */
  --color-gray1: #616161;
  --color-gray2: #3b3b3b;


  --color-nav-link-no-active: #9c9c9c;
  --color-nav-link-active: #242323;

  --bg-soluciones: #191724;
  --bg-brands: #363454;

  --bg-service-block1: #191724;
  --bg-service-block2: #111224;

  /* VARIABLES TAMAÑO PANTALLA */
  --phoneScreen : "650px"

}

/* .tituloBlock{
  
} */