.main {
   /* border: 2px solid blue; */

   position: fixed;
   bottom: 20px;
   left: 50px;

   width: 65px;

   animation-duration: 2000ms;
   animation-name: softAnimation;
   animation-delay: 500ms;
   animation-iteration-count: infinite;

   z-index: 50;

}

.logoStyle {
   width: 100%;

}

.logoStyle:hover {
   width: 100%;


}

.main:hover {
   cursor: pointer;
   /* width: 75px; */

   /* transform: translate(-5px, 5px); */


}

@keyframes softAnimation {

   0% {
      width: 65px;
   }

   45% {
      width: 65px;
      /* transform: translate(-5px, 5px); */
   }

   50% {
      width: 75px;
      /* transform: translate(-5px, 5px); */
   }

   55% {
      width: 65px;
      /* transform: translate(-5px, 5px); */
   }

   100% {
      width: 65px;
   }
}