
.navBarCustomStyle{
    position: sticky;
    top: 0;
    background-color: red;

}

.navBarCustomUl{
    background-color: red;
}

.navLink{
    text-decoration: none;
    color: var(--color-nav-link-no-active);
    font-weight: 700;
    transition-duration: 400ms;
    transition-property: all;
}

.navLink:hover{
    color: var(--color-nav-link-active);
}



.navLink::before{
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 4px;
    background: var(--vitech-blue-light);
    z-index: 1;
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
}
.navLink:hover::before{
    border-bottom: 0px;
    transform: scaleX(1);
}

.activeNavLink{
    composes: navLink;
    color: var(--color-nav-link-active);    
}

.contactUsLinkContainer{
    text-decoration: none;
    font-weight: 700;

    align-self: center;
    justify-self: center;

    padding: 5px 20px;
    border: 0px solid red;
    border-radius: 50px;
    color: white;
    background-color: var(--vitech-blue);
}
.contactUsLinkContainer:hover{
    color: white;
    background-color: var(--vitech-blue-light);
}









