
.footer{
   background-color: var(--color-white1);
   padding: 40px 0px;
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;

   color: var(--color-gray1);
   font-size: 15px;
}

.footer1, .footer2, .footer3{
   padding: 5px;
   /* border: 1px solid yellow; */
}

.footer1{
   display: flex;
   align-items: center;
}

.footer2{
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   align-items: center;
   flex-wrap: wrap;
}.link{
   
   /* border: 1px solid red; */
   color: var(--color-gray1);
   /* font-weight: bold; */
   text-decoration: none;
   margin: 0 15px;
}

.footer3{
   
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: center;
}



@media (max-width: 700px) {
   .footer3, .footer2{
      width: 100%;
   }
   .footer2{
      margin: 20px 0;
   }

   .footer3{
      align-items: center;
   }
}