.darkImg{
    background-color: rgba(0, 0, 0, 0.5);
    /* border: 1px solid blue; */
    width: 100%;
    height: 100%;

    position: absolute;
}
.sliderTextContainer{
    /* padding: 30px; */
    /* position: absolute; */
    top: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-blend-mode: darken;

    /* border: 1px solid white; */

}

.titleSlide{
    font-size: xx-large;
    font-weight: 700;

    animation-name: aparicion;
    animation-duration: 1.6s;
}

.paragraphSlide{    
    font-size: 2.4rem;
    font-weight: 200;
    line-height: 100%;

   
    animation-name: aparicion;
    animation-duration: 3.6s;
}

.slideImg{
    height: 500px;
    object-fit: cover;
}

@media (max-width: 650px){
    /* .sliderTextContainer{
    color: yellow;
    } */
    .slideImg{
        height: 300px;
        object-fit: cover;
    }

    .titleSlide{
        font-size: large;
        font-weight: 700;
    }
    .paragraphSlide{    
        font-size: 100%;
        font-weight: 200;
        line-height: 100%;
    }
    
}


/*  ANIMACIONES */


@keyframes aparicion {
    0%{
        position: relative;
        bottom: 20px;
        opacity: 0;
    }

    100%{
        position: relative;
        bottom: 0px;
        opacity: 1;
    }
    
}