
.mainImageContainer{
   /* border: 2px solid blue; */

   position: relative;

   
}.mainImageContainer>img{
   width: 100%;

   min-height: 300px;
   max-height: 390px;
   object-fit: cover;
   animation-duration: 1800ms;
   animation-name: desvanecido-imagen;
}

.titlesContainer{
   position: absolute;
   width: 90%;
   height: 120px;
   top: 50%;
   left: 50%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: column;

   /* border: 1px solid white; */
   transform: translate(-50%,-50%);
   font-weight: lighter;
   color: white;

   animation-duration: 1800ms;
   animation-name: desvanecido-imagen;
}.titlesContainer > .subtitle{font-weight: 200;}

@keyframes desvanecido-imagen {
   0%{
      width: 110%;
      opacity: 0.3;
   }
   100%{

      opacity: 1;
   }
   
}


